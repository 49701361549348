import { AnyAction } from 'redux';
import { UserInterface } from '../../interfaces/user';
import { PropertyInterface } from '../../interfaces/property';

export enum PropertyActionTypes {
  SET_PROPERTY = 'SET_PROPERTY',
  SET_PROPERTY_USERS = 'SET_PROPERTY_USERS',
}

export interface AddPropertyAction extends AnyAction {
  type: PropertyActionTypes.SET_PROPERTY;
  payload: PropertyInterface;
}

export interface AddPropertyUsersAction extends AnyAction {
  type: PropertyActionTypes.SET_PROPERTY_USERS;
  payload: UserInterface[];
}

export type PropertyAction = AddPropertyAction | AddPropertyUsersAction;

export const setProperty = (property: PropertyInterface): AddPropertyAction => {
  return {
    payload: property,
    type: PropertyActionTypes.SET_PROPERTY,
  };
};

export const setPropertyUsers = (users: UserInterface[]): AddPropertyUsersAction => {
  return {
    payload: users,
    type: PropertyActionTypes.SET_PROPERTY_USERS,
  };
};
