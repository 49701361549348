import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '../styleguide/StyledTypography';
import { TextLogo } from './Logo';
import { respondTo } from 'styleguide/breakpoints';
import { Link } from 'react-router-dom';
import { ROUTES } from '../utilities/constants';
const { LargeLead } = Typography;

interface NavItemProps {
  name: string;
}

interface NavBarProps {
  items: string[];
}

const NavBarWrapper = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.space.S}px ${({ theme }) => theme.space.L}px;
  background-color: #ffffff;
  align-items: center;

  ${respondTo.sm`
    height: 64px;
    display: block;
  `}
`;

const StyledLogo = styled(TextLogo)`
  align-self: center;
  width: auto;
  height: 60px;
  margin: 0 20px;

  ${respondTo.sm`
    margin: ${({ theme }) => theme.space.M}px;
  `}
`;

const NavBarContainer = styled.ul`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 40px;
`;

const NavItemContainer = styled.li`
  list-style: none;
  margin-left: 40px;
  color: ${({ theme }) => theme.colors.secondary.base};
`;

const NavItem: FC<NavItemProps> = ({ name }) => (
  <NavItemContainer>
    <LargeLead>{name}</LargeLead>
  </NavItemContainer>
);

const NavBar: FC<NavBarProps> = ({ items }) => (
  <NavBarWrapper>
    <Link to={ROUTES.HOME}>
      <StyledLogo />
    </Link>
    <NavBarContainer>
      {items.map((itemName) => (
        <NavItem key={itemName} name={itemName} />
      ))}
    </NavBarContainer>
  </NavBarWrapper>
);

export default NavBar;
