import styled from 'styled-components';
import { respondTo } from '../styleguide/breakpoints';
import loaderIcon from '../assets/images/loader.gif';

const BorderedButton = styled.button`
  background-color: white;
  padding: ${({ theme }) => theme.space.M}px;
  margin: ${({ theme }) => theme.space.M}px 0;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.primary.base};
  color: ${({ theme }) => theme.colors.primary.base};
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);

  width: 100%;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey.base};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.white.base};
  }

  &.loading {
    background-image: url(${loaderIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
  }

  ${respondTo.md`
    width: 100%;
  `}
`;

const FilledButton = styled(BorderedButton)`
  background-color: ${({ theme }) => theme.colors.primary.base};
  border: none;
  color: white;
`;

export { BorderedButton, FilledButton };
