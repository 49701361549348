import { gql } from '@apollo/client';

const $UserFragment$ = gql`
  fragment UserProfile on User {
    user_id
    email
    firstname
    lastname
    phone_number
    picture
    location
  }
`;

const $MeterFragment$ = gql`
  fragment MeterProfile on Meter {
    meter_id
    meter_code
    site_id
    property_id
    gateway_id
    user_id
    name
    sn
    address
    info
    data_opt
    meter_tariff
    meter_type
    meter_billing_type
    comms
    debt
    units
    is_enabled
    has_camera
    created_at
    updated_at
  }
`;

const $PaymentFragment$ = gql`
  fragment PaymentProfile on Payment {
    payment_id
    site_id
    property_id
    gateway_id
    meter_id
    units
    amount_paid
    payment_status
    raw_response
    created_at
    updated_at
  }
`;
const $PrePaymentFragment$ = gql`
  fragment PrePaymentProfile on PrePayment {
    units
    additions
    subtractions
    divisors
  }
`;

const $GatewayFragment$ = gql`
  fragment GatewayProfile on Gateway {
    gateway_id
    site_id
    property_id
    name
    priv_ipv4
    pub_ipv4
    mac_address
    firmware
    remote_access
    logging_interval
    is_enabled
    has_camera
    commissioned_at
    created_at
    updated_at
  }
`;

const $PropertyFragment$ = gql`
  fragment PropertyProfile on Property {
    property_id
    creator_id
    email
    name
    phone_number
    address
    url
    logo
    about
    description
    service_charge
    vat
    created_at
  }
`;

const $AlertEmailFragment$ = gql`
  fragment AlertEmailProfile on AlertEmail {
    id
    email
    settings {
      report {
        daily
        weekly
        monthly
        yearly
        throttle
      }
    }
  }
`;

const $PropertySettingsFragment$ = gql`
  fragment PropertySettingsProfile on PropertySettings {
    report {
      daily
      weekly
      monthly
      yearly
      throttle
    }
  }
`;

const $UserCardFragment$ = gql`
  fragment UserCardProfile on UserCard {
    card_id
    user_id
    card_code
    is_default
    is_read_debit_card
    card_number
    card_name
    expiry_date
    cvv
    card_type
    card_color
    img_url
    token
    created_at
    updated_at
  }
`;

export {
  $UserFragment$,
  $MeterFragment$,
  $PaymentFragment$,
  $PrePaymentFragment$,
  $GatewayFragment$,
  $PropertyFragment$,
  $AlertEmailFragment$,
  $PropertySettingsFragment$,
  $UserCardFragment$,
};
