import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LandingContainer from '../components/LandingContainer';
import Input from '../components/Input';
import { FilledButton } from '../components/Button';
import styled from 'styled-components';
import { ROUTES, STORAGE_KEY } from '../utilities/constants';
import { respondTo } from '../styleguide/breakpoints';
import { Typography as Typo } from '../styleguide/StyledTypography';
import { useMutation } from '@apollo/client';
import { $LOG_IN } from '../queries';
import { setUserData, setProperty } from '../store/actions';

const StyledTitle = styled(Typo.H3)`
  margin: 0 0 ${({ theme }) => theme.space.L}px;
`;

const StyledForm = styled.form`
  padding: ${({ theme }) => theme.space.L}px;
  text-align: center;
  max-width: 560px;
  width: 100%;
  margin: auto;
  padding-top: 50px;

  ${respondTo.sm`
    text-align: left;
    padding: 0 50px 50px 50px;
  `}

  ${respondTo.md`
    padding: 20px 100px 100px 100px;
  `}
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [logIn, { loading }] = useMutation($LOG_IN);
  const [credential, setCredential] = useState({ email: '', password: '' });

  const handleCredentialChange = (e) => {
    const name = e.target.name,
      value = e.target.value;
    setCredential((credential) => ({ ...credential, [name]: value }));
  };

  return (
    <LandingContainer>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          logIn({ variables: { email: credential.email, password: credential.password } })
            .then(({ data }) => {
              const user = data && data.signin;

              if (user) {
                const property = (user.properties || [])[0] || {};
                dispatch(setProperty(property));

                dispatch(setUserData(user));

                localStorage.setItem(btoa(STORAGE_KEY.TOKEN), user.token);

                history.push(ROUTES.DASHBOARD);
              }
            })
            .catch((e) => {
              console.log('E', e);
            });
        }}
      >
        <StyledTitle>Sign in</StyledTitle>
        <Input
          id="email"
          name="email"
          placeholder="Email"
          type="email"
          onChange={handleCredentialChange}
          value={credential.email}
          required
        />
        <Input
          name="password"
          placeholder="Password"
          type="password"
          onChange={handleCredentialChange}
          value={credential.password}
          required
        />
        <FilledButton type="submit" disabled={loading}>
          Log In
        </FilledButton>
      </StyledForm>
    </LandingContainer>
  );
};

export default LoginPage;
