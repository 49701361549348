import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import LandingContainer from '../components/LandingContainer';
import { FilledButton, BorderedButton } from '../components/Button';
import styled from 'styled-components';
import { ROUTES } from '../utilities/constants';
import { Typography } from '../styleguide/StyledTypography';
import { $USER_PROFILE } from '../queries';
import { setUserData, logOutUser } from '../store/actions';
import { useSelector } from 'react-redux';
import { getUser } from '../store/selectors';
import { UserInterface } from '../interfaces/user';

const { H3 } = Typography;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px;
  max-width: 560px;
  width: 100%;
  margin: auto;
`;

const Landing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fetchUserProfile, { data, loading, called }] = useLazyQuery($USER_PROFILE);
  const user: UserInterface = useSelector(getUser);

  useEffect(() => {
    if (!data) {
      fetchUserProfile();
    } else {
      if (!loading && called && data && data.user) {
        dispatch(setUserData(data.user));
      }
    }
  }, [data]);

  const logOut = () => {
    localStorage.clear();
    dispatch(logOutUser());
  };

  return (
    <LandingContainer>
      <StyledButtonContainer>
        <H3>Hi {(user && user.firstname) || 'there'}!</H3>
        {user && user.token && (
          <div style={{ display: 'flex' }}>
            <FilledButton style={{ marginRight: 10 }} onClick={() => history.push(ROUTES.DASHBOARD)}>
              Dashboard
            </FilledButton>
            <FilledButton onClick={() => logOut()}>Log Out</FilledButton>
          </div>
        )}
        {!(user && user.token) && <FilledButton onClick={() => history.push(ROUTES.LOGIN)}>Log In To Dashboard</FilledButton>}
        <a href="mailto:support@etrack.africa">
          <BorderedButton>Report an Issue</BorderedButton>
        </a>
      </StyledButtonContainer>
    </LandingContainer>
  );
};

export default Landing;
