import { gql } from '@apollo/client';
import { $UserFragment$, $MeterFragment$, $PropertyFragment$, $UserCardFragment$, $PaymentFragment$ } from './fragments';

const $LOG_IN = gql`
  mutation logIn($email: EmailAddress!, $password: String!) {
    signin(email: $email, password: $password) {
      ...UserProfile
      token
      cards {
        ...UserCardProfile
      }
      properties {
        ...PropertyProfile
      }
    }
  }
  ${$UserFragment$}
  ${$PropertyFragment$}
  ${$UserCardFragment$}
`;

const $SIGN_UP = gql`
  mutation signUp($email: EmailAddress!, $password: String!, $firstname: String!, $lastname: String!) {
    signup(email: $email, password: $password, firstname: $firstname, lastname: $lastname) {
      ...UserProfile
    }
  }
  ${$UserFragment$}
`;

const $DELETE_ACCOUNT = gql`
  mutation deleteAccount($email: EmailAddress!) {
    deleteAccount(email: $email)
  }
`;

const $UPDATE_PASSWORD = gql`
  mutation updatePassword($old_password: String!, $password: String!) {
    updatePassword(old_password: $old_password, password: $password)
  }
`;

const $RESET_PASSWORD = gql`
  mutation resetPassword($reset_token: String!, $password: String!) {
    resetPassword(reset_token: $reset_token, password: $password)
  }
`;

const $LINK_TENANT_TO_METER = gql`
  mutation linkTenantToMeter($meter_id: ID!, $tenant_id: ID!) {
    linkTenantToMeter(meter_id: $meter_id, tenant_id: $tenant_id) {
      ...MeterProfile
    }
  }
  ${$MeterFragment$}
`;

const $ADD_TENANTS_BY_CSV = gql`
  mutation addMultipleUsersByCSV($property_id: ID!, $role_slug: String!, $file: Upload!) {
    addMultipleUsersByCSV(property_id: $property_id, role_slug: $role_slug, file: $file) {
      ...UserProfile
    }
  }
  ${$UserFragment$}
`;

const $ADD_TENANT_TO_PROPERTY = gql`
  mutation addSingleUser(
    $property_id: ID!
    $role_slug: String!
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone_number: String!
  ) {
    addSingleUser(
      property_id: $property_id
      role_slug: $role_slug
      email: $email
      firstname: $firstname
      lastname: $lastname
      phone_number: $phone_number
    ) {
      ...UserProfile
    }
  }
  ${$UserFragment$}
`;

const $REMOVE_TENANT_TO_PROPERTY = gql`
  mutation removeUser($property_id: ID!, $user_id: ID!) {
    removeUser(property_id: $property_id, user_id: $user_id)
  }
`;

const $UPDATE_METER_BILLING = gql`
  mutation updateMeterBilling($meter_code: ID!, $tariff: Float, $debt: Float, $billing_type: String) {
    updateMeterBilling(meter_code: $meter_code, tariff: $tariff, debt: $debt, billing_type: $billing_type) {
      ...MeterProfile
    }
  }
  ${$MeterFragment$}
`;

const $ADD_METER_DEBT = gql`
  mutation updateMeterBilling($meter_code: ID!, $tariff: Float, $debt: Float, $billing_type: String) {
    updateMeterBilling(meter_code: $meter_code, tariff: $tariff, debt: $debt, billing_type: $billing_type) {
      ...MeterProfile
    }
  }
  ${$MeterFragment$}
`;

const $MAKE_PAYMENT_METER = gql`
  mutation payment($meter_code: ID!, $property_id: ID!, $amount_paid: Float!, $payment_status: String!, $raw_response: JSONObject!) {
    payment(
      meter_code: $meter_code
      property_id: $property_id
      amount_paid: $amount_paid
      payment_status: $payment_status
      raw_response: $raw_response
    ) {
      ...PaymentProfile
    }
  }
  ${$PaymentFragment$}
`;

export {
  $LOG_IN,
  $SIGN_UP,
  $ADD_METER_DEBT,
  $DELETE_ACCOUNT,
  $RESET_PASSWORD,
  $UPDATE_PASSWORD,
  $LINK_TENANT_TO_METER,
  $ADD_TENANTS_BY_CSV,
  $ADD_TENANT_TO_PROPERTY,
  $REMOVE_TENANT_TO_PROPERTY,
  $UPDATE_METER_BILLING,
  $MAKE_PAYMENT_METER,
};
