import React from 'react';
import styled from 'styled-components';
import logoUrl from '../assets/images/etrack/logo-green.png';
import logoWhiteUrl from '../assets/images/etrack/logo-white.png';
import logoTextWhiteUrl from '../assets/images/etrack/logo-y-green.png';

const StyledLogo = styled.img`
  width: 184px;
  height: 188px;
`;

const StyledTextLogo = styled.img`
  width: 189px;
  height: 50px;
`;

const Logo: React.FC<{
  alternate?: boolean;
  className?: string;
  logo?: any;
}> = ({ alternate = false, className, logo }) => <StyledLogo className={className} src={logo || (alternate ? logoWhiteUrl : logoUrl)} />;

export const TextLogo: React.FC<{
  className?: string;
}> = ({ className }) => <StyledTextLogo className={className} src={logoTextWhiteUrl} />;

export default Logo;
