import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ROLE_PERMISSION, ROUTES, STORAGE_KEY } from '../utilities/constants';
import { getUser } from '../store/selectors';

export const RestrictedRoute = ({ component: Component, allowedPermissions = [], ...rest }: { [key: string]: any }) => {
  const user = useSelector(getUser);
  let isSignedIn = false;

  if (!user) {
    const token = localStorage.getItem(btoa(STORAGE_KEY.TOKEN));
    isSignedIn = !!token;
  } else {
    isSignedIn = !!(user && user.token);
  }

  const permissions: string[] = user && user.role ? ROLE_PERMISSION[user.role.slug] : [];

  // TODO:: Remove `permissions.length === 0 ||`
  const allowNavigation = permissions.length === 0 || !!permissions.find((permision: string) => allowedPermissions.includes(permision));

  return (
    <Route
      {...rest}
      render={(props) =>
        isSignedIn ? allowNavigation ? <Component {...props} /> : <Redirect to={ROUTES.HOME} /> : <Redirect to={ROUTES.LOGIN} />
      }
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const PrivateRoute = ({ component: Component, backwardPass = false, ...rest }) => {
  const user = useSelector(getUser);
  let isSignedIn = false;

  if (!user) {
    console.log('UUUUU: ', user);
    const token = localStorage.getItem(btoa(STORAGE_KEY.TOKEN));
    isSignedIn = !!token;
  } else {
    isSignedIn = !!(user && user.token);
  }

  if (backwardPass) {
    isSignedIn = !isSignedIn;
  }

  return (
    <Route
      {...rest}
      render={(props) => (isSignedIn ? <Component {...props} /> : <Redirect to={backwardPass ? ROUTES.DASHBOARD : ROUTES.LOGIN} />)}
    />
  );
};
