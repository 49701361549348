import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { STORAGE_KEY } from '../utilities/constants';

const httpLink = createUploadLink({
  uri: 'https://api.tenant.etrack.africa/graphql/v1',
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const token = localStorage.getItem(btoa(STORAGE_KEY.TOKEN));

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '', // however you get your token
        ...headers,
      },
    };
  });

  return forward(operation);
});

const errorCheck = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: from([errorCheck, authLink, httpLink]),
  cache: new InMemoryCache(),
});
