import { darken, lighten } from 'polished';
import { options, rhythm } from './typography';
import { space } from './space';

const colors = {
  black: {
    base: '#000000',
  },
  white: {
    base: '#ffffff',
  },
  primary: {
    base: '#0ca04b',
    dark: `${darken(0.07, '#0ca04b')}`,
    light: `${lighten(0.15, '#0ca04b')}`,
  },
  secondary: {
    base: '#00263e',
    dark: `${darken(0.05, '#00263e')}`,
    light: `#eef2f8`,
  },
  grey: {
    base: '#ababab',
    dark: `${darken(0.05, '#ababab')}`,
    light: `${lighten(0.05, '#ababab')}`,
  },
  red: {
    base: '#e0483d',
    dark: `${darken(0.05, '#e0483d')}`,
    light: `${lighten(0.05, '#e0483d')}`,
  },
  green: {
    base: '#6fd987',
    dark: `${darken(0.05, '#6fd987')}`,
    light: `${lighten(0.05, '#6fd987')}`,
  },
  blue: {
    base: '#6fa2d9',
    dark: `${darken(0.05, '#6fa2d9')}`,
    light: `${lighten(0.05, '#6fa2d9')}`,
  },
  yellow: {
    base: '#fcb415',
    dark: `${darken(0.05, '#fcb415')}`,
    light: `${lighten(0.05, '#fcb415')}`,
  },
  indigo: {
    base: '#163457',
    dark: '#111d2e',
    light: `${lighten(0.05, '#0c1c2e')}`,
  },
};

const breakpoints = {
  xs: 576,
  sm: 767,
  md: 967,
  lg: 1023,
  xl: 1200,
};

const general = {
  media: {
    maxXS: `@media (max-width: ${breakpoints.xs}px)`,
    maxSM: `@media (max-width: ${breakpoints.sm}px)`,
    maxMD: `@media (max-width: ${breakpoints.md}px)`,
    maxLG: `@media (max-width: ${breakpoints.lg}px)`,
    tabletOnly: `@media (min-width: ${breakpoints.xs + 1}px) and (max-width: ${breakpoints.md}px)`,
    desktopOnly: `@media (min-width: ${breakpoints.md + 1}px)`,
  },
  container: {
    base: rhythm(100),
    text: rhythm(55),
  },
  spacer: {
    horizontal: rhythm(2),
    vertical: rhythm(3),
  },
  transition: {
    ease: 'all 300ms ease',
  },
};

const theme = {
  ...options,
  ...general,
  breakpoints,
  colors,
  space,
  headerColor: colors.white.base,
  bodyColor: colors.secondary.base,
  linkColor: colors.primary.base,
  linkHoverColor: colors.primary.dark,
  bgColor: colors.white.base,
};

export default theme;
