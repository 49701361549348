import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '../components/Table';
import DashboardContainer from '../components/DashboardContainer';
import { useLazyQuery } from '@apollo/client';
import { $GET_PROPERTY_GATEWAYS } from '../queries';
import { getProperty } from '../store/selectors';
import { PropertyInterface } from '../interfaces/property';
import { formatDate } from '../utilities/helpers';

const gatewayColumns = [
  { Header: 'Gateway', accessor: 'name' },
  { Header: 'Log Interval', accessor: 'logging_interval' },
  { Header: 'Enabled?', accessor: 'is_enabled' },
  { Header: 'Camera?', accessor: 'has_camera' },
  { Header: 'Remote Access', accessor: 'remote_access' },
  { Header: 'Firmware', accessor: 'firmware' },
  { Header: 'Pub. IP', accessor: 'pub_ipv4' },
  { Header: 'Priv. IP', accessor: 'priv_ipv4' },
  { Header: 'MAC Addr', accessor: 'mac_address' },
  { Header: 'Commissioned', accessor: 'commissioned_at' },
];

const meterColumns = [
  { Header: 'Meter', accessor: 'name' },
  { Header: 'Type', accessor: 'meter_type' },
  { Header: 'Address', accessor: 'address' },
  { Header: 'Billing', accessor: 'meter_billing_type' },
  { Header: 'Tariff', accessor: 'meter_tariff' },
  { Header: 'Debt', accessor: 'debt' },
  { Header: 'Gateway', accessor: 'gateway_name' },
  { Header: 'Created', accessor: 'created_at' },
];

const Meters = () => {
  const property: PropertyInterface = useSelector(getProperty);
  const [fetchGateways, { data }] = useLazyQuery($GET_PROPERTY_GATEWAYS);

  useEffect(() => {
    if (property) {
      fetchGateways({ variables: { property_id: property.property_id } });
    }
  }, [property]);

  return (
    <DashboardContainer activeItem="meters">
      {data && data.gateways && (
        <>
          <Table
            data={data.gateways.map(({ is_enabled, has_camera, commissioned_at, ...gateway }) => ({
              ...gateway,
              has_camera: has_camera ? 'Yes' : 'No',
              is_enabled: is_enabled ? 'Yes' : 'No',
              commissioned_at: formatDate(commissioned_at),
            }))}
            columns={gatewayColumns}
          />
          <Table
            data={
              data &&
              data.gateways.reduce((allMeters, gateway) => {
                allMeters.push(
                  ...gateway.meters.map(({ created_at, ...meter }) => ({
                    ...meter,
                    gateway_name: gateway.name,
                    created_at: formatDate(created_at),
                  }))
                );
                return allMeters;
              }, [])
            }
            columns={meterColumns}
          />
        </>
      )}
    </DashboardContainer>
  );
};

export default Meters;
