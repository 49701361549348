import { gql } from '@apollo/client';
import {
  $UserFragment$,
  $MeterFragment$,
  $PaymentFragment$,
  $PrePaymentFragment$,
  $GatewayFragment$,
  $PropertyFragment$,
  $AlertEmailFragment$,
  $PropertySettingsFragment$,
} from './fragments';

const $USER_PROFILE = gql`
  query userProfile {
    user {
      token
      ...UserProfile
      properties {
        ...PropertyProfile
      }
    }
  }
  ${$UserFragment$}
  ${$PropertyFragment$}
`;

const $USER_FULL_PROFILE = gql`
  query userProfile {
    user {
      token
      ...UserProfile
      properties {
        ...PropertyProfile
        users {
          ...UserProfile
        }
        creator {
          ...UserProfile
        }
        alert_emails {
          id
          email
        }
        settings {
          report {
            daily
            weekly
            monthly
            yearly
            throttle
          }
        }
        gateways {
          ...GatewayProfile
          meters {
            ...MeterProfile
            payments {
              ...PaymentProfile
            }
          }
          payments {
            ...PaymentProfile
          }
        }
        meters {
          ...MeterProfile
          payments {
            ...PaymentProfile
          }
        }
        payments {
          ...PaymentProfile
        }
      }
    }
  }
  ${$UserFragment$}
  ${$MeterFragment$}
  ${$PaymentFragment$}
  ${$GatewayFragment$}
  ${$PropertyFragment$}
  ${$AlertEmailFragment$}
  ${$PropertySettingsFragment$}
`;

const $FORGOT_PASSWORD = gql`
  query forgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email)
  }
`;

const $GET_PROPERTY = gql`
  query getProperty($property_id: ID!) {
    property(property_id: $property_id) {
      ...PropertyProfile
    }
  }
  ${$PropertyFragment$}
`;

const $GET_PROPERTY_GATEWAYS = gql`
  query getPropertyGateways($property_id: ID!, $site_code: String) {
    gateways(property_id: $property_id, site_code: $site_code) {
      ...GatewayProfile
      meters {
        ...MeterProfile
      }
    }
  }
  ${$MeterFragment$}
  ${$GatewayFragment$}
`;

const $GET_PROPERTY_USERS = gql`
  query getPropertyUsers($property_id: ID!) {
    property(property_id: $property_id) {
      ...PropertyProfile
      users {
        ...UserProfile
        meters {
          ...MeterProfile
        }
      }
    }
  }
  ${$UserFragment$}
  ${$MeterFragment$}
  ${$PropertyFragment$}
`;

const $GET_GATEWAY_METERS = gql`
  query getGatewayMeters($property_id: ID!, $site_code: String, $gateway_id: ID) {
    meters(property_id: $property_id, site_code: $site_code, gateway_id: $gateway_id) {
      ...MeterProfile
    }
  }
  ${$MeterFragment$}
`;

const $GET_PROPERTY_PAYMENTS = gql`
  query getPropertyPayments($property_id: ID!, $gateway_id: ID) {
    payments(property_id: $property_id, gateway_id: $gateway_id) {
      ...PaymentProfile
    }
  }
  ${$PaymentFragment$}
`;

const $GET_METER_PRE_PAYMENTS = gql`
  query getMeterPrePayments($meter_id: ID!, $property_id: ID!, $amount: Float!) {
    prePayment(meter_id: $meter_id, property_id: $property_id, amount: $amount) {
      ...PrePaymentProfile
    }
  }
  ${$PrePaymentFragment$}
`;

const $PING_METER_LIVENESS = gql`
  query pingMeterLiveness($meter_code: ID!) {
    pingMeterLiveness(meter_code: $meter_code) {
      is_available
    }
  }
`;

export {
  $USER_PROFILE,
  $USER_FULL_PROFILE,
  $FORGOT_PASSWORD,
  $GET_PROPERTY,
  $GET_PROPERTY_USERS,
  $GET_PROPERTY_GATEWAYS,
  $GET_GATEWAY_METERS,
  $GET_PROPERTY_PAYMENTS,
  $GET_METER_PRE_PAYMENTS,
  $PING_METER_LIVENESS,
};
