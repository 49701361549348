import { combineReducers } from 'redux';
import userReducer from './user';
import { UserActionTypes } from '../actions';
import propertyReducer from './property';

const appReducer = combineReducers({
  user: userReducer,
  property: propertyReducer,
});

const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.LOG_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
