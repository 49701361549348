const config: { forage: any } = {
  forage: {
    storeName: process.env.REACT_APP_STORE_NAME,
    name: process.env.REACT_APP_STORAGE_NAME,
    version: process.env.REACT_APP_STORE_VERSION,
    description: process.env.REACT_APP_STORE_DESCRIPTION,
  },
};

export default config;
