export const APP_NAME = 'Etrack Tenant';

export enum ROUTES {
  HOME = '/',
  LOGIN = '/signin',
  SIGNUP = '/signup',
  DASHBOARD = '/tenants',
  METERS = '/meters',
  TENANTS = '/tenants',
  TENANT_VIEW = '/tenants/',
  TENANT_VIEW_PARAM = '/tenants/:email_hash',
  MANAGEMENT = '/management',
}

export const PERMISSIONS = {
  CREATE_TENANT: 'CREATE_TENANT',
  MANAGE_TENANT: 'MANAGE_TENANT',
  MANAGE_METERS: 'MANAGE_METERS',
  VIEW_TENANTS: 'VIEW_TENANTS',
  VIEW_WALLET: 'VIEW_WALLET',
  SAVE_WALLET: 'SAVE_WALLET',
  VIEW_REPORT: 'VIEW_REPORT',
};

export const ROLE_PERMISSION = {};

export const STORAGE_KEY = {
  USER: 'user',
  TOKEN: 'token',
};

export const PAYMENT_STATUS_MAP = {
  0: 'failed',
  1: 'success',
};
