import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { ROUTES } from '../utilities/constants';
import styled from 'styled-components';
import BurgerMenuIcon from './BurgerMenuIcon';
import Panel from './Panel';
import { respondTo } from '../styleguide/breakpoints';
import defaultAvatar from '../assets/images/avatar.png';
import Select from '../components/Select';
import { setProperty } from '../store/actions';
import { getUser, getProperty } from '../store/selectors';
import { UserInterface } from '../interfaces/user';
import { PropertyInterface } from '../interfaces/property';

export const DashboardPanelItems = {
  // dashboard: {
  //   route: ROUTES.DASHBOARD,
  //   label: 'Dashboard',
  // },
  // meters: {
  //   route: ROUTES.METERS,
  //   label: 'Meters',
  // },
  tenants: {
    route: ROUTES.TENANTS,
    label: 'Tenants',
  },
  // management: {
  //   route: ROUTES.MANAGEMENT,
  //   label: 'Management',
  // },
};

const StyledLogo = styled(Logo)`
  width: 50px;
  height: 50px;
`;

const StyledAvatarLogo = styled(Logo)`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid #ffffff;
`;

const StyledBurgerMenuContainer = styled.div`
  ${respondTo.md`
    display: none;
  `}
`;

const StyledDashboard = styled.div`
  display: flex;
`;

const StyledPanelHeader = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  > a {
    margin: 0 10px;
  }

  img {
    margin: 0;
  }
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: 100vh;
  padding-top: 10px;
  background-color: ${({ theme }) => theme.colors.primary.base};
  z-index: 10;

  // Todo: remove
  display: none;

  ${respondTo.sm`
    display: flex;
    width: 20%;
    padding-top: 10px;
  `}
`;

const StyledPanelContainer = styled.div`
  overflow-y: auto;
`;

const StyledDashboardContent = styled.div`
  position: relative;

  ${respondTo.sm`
    width: 80%;
    left: 20%;
  `}
`;

const StyledPropertyContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content; center;
  padding: 0 10px;
`;

export interface DashboardContainerProps {
  activeItem?: any;
}

const DashboardContainer: FC<DashboardContainerProps> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user: UserInterface = useSelector(getUser);
  const property: PropertyInterface = useSelector(getProperty);

  return (
    <StyledDashboard>
      <StyledBurgerMenuContainer>
        <BurgerMenuIcon />
      </StyledBurgerMenuContainer>
      <StyledPanelWrapper>
        <StyledPanelHeader>
          <Link to={ROUTES.HOME}>
            <StyledLogo alternate />
          </Link>
          {user && (
            <Link to={ROUTES.HOME}>
              <StyledAvatarLogo alternate logo={user.picture || defaultAvatar} />
            </Link>
          )}
        </StyledPanelHeader>
        <StyledPanelContainer>
          <Panel items={Object.values(DashboardPanelItems)} />
        </StyledPanelContainer>
        {user.properties && (
          <StyledPropertyContainer>
            <Select
              defaultValue={property && property.property_id}
              options={user.properties.map(({ property_id, name }) => ({ value: property_id, option: name }))}
              placeholder="- Choose Property -"
              onChange={(e) => {
                const propertyId = e.target.value;
                const property = user.properties.find(({ property_id }) => property_id == propertyId);

                dispatch(setProperty(property));

                if (history.location.pathname !== ROUTES.TENANTS) {
                  history.push(ROUTES.TENANTS);
                }
              }}
            />
          </StyledPropertyContainer>
        )}
      </StyledPanelWrapper>
      <StyledDashboardContent>{children}</StyledDashboardContent>
    </StyledDashboard>
  );
};

export default DashboardContainer;
