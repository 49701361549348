import ReduxThunk from 'redux-thunk';
import logger from 'redux-logger';
import { applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares: any[] = [ReduxThunk];
let composer: any = compose;

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger); // Logger must be last
  composer = composeWithDevTools;
}

const enhancer = composer(applyMiddleware(...middlewares));

export default enhancer;
