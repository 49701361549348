import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ROUTES, PAYMENT_STATUS_MAP } from '../../utilities/constants';
import { getPropertyUsers } from '../../store/selectors';
import Toast from '../../components/Toast';
import Input from '../../components/Input';
import Modal from 'react-bootstrap/Modal';
import { useMutation, useApolloClient } from '@apollo/client';
import { $ADD_METER_DEBT, $MAKE_PAYMENT_METER, $PING_METER_LIVENESS } from '../../queries';
import closeIcon from '../../assets/images/close.svg';
import { FilledButton } from '../../components/Button';
import Select from '../../components/Select';
import DashboardContainer from '../../components/DashboardContainer';

const StyledContentWrapper = styled.div`
  padding: 20px;
`;

const StyledInfoContainer = styled.div`
  padding: 10px 20px;
`;

const StyledInfo = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 20px;

  span:first-child {
    width: 200px;
  }

  span:not(:first-child) {
    font-weight: 600;
  }
`;

const StyledInfoTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary.base};
  font-weight: 500;
  margin-top: 10px;
`;

const StyledButton = styled.div<{ color: string }>`
  color: ${({ theme }) => theme.colors.white.base};
  margin: 0 10px;
  font-size: 12px;
  background: ${({ theme, color }) => theme.colors[color].base};
  border-radius: 8px;
  padding: 2px 10px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background: ${({ theme, color }) => theme.colors[color].dark};
  }
`;

const TenantProfile = (props) => {
  const history = useHistory();
  const [addDebt, { loading }] = useMutation($ADD_METER_DEBT);
  const client = useApolloClient();
  const [makePayment, { loading: makePaymentLoading }] = useMutation($MAKE_PAYMENT_METER);
  const [activeMeter, setActiveMeter]: any = useState({
    meter_code: '',
    value: 0,
    billing_type: '',
    tariff: 0,
    payment_type: 'card',
    payment_status: '1',
    message: 'Charge success',
    tx_ref: '',
  });
  const [openModal, setOpenModal]: any = useState({ debt: false, payment: false });
  const [tenant, setTenant]: any[] = useState({ meters: [] });
  const [toast, setToast]: any = useState({ show: false, message: '', title: '', success: false, error: false });
  const propertyUsers: any[] = useSelector(getPropertyUsers);
  const emailHash = props.match.params.email_hash;

  const handleModalClose = () => {
    setOpenModal({ debt: false, payment: false });
  };

  useEffect(() => {
    try {
      const email = atob(emailHash);

      if (email) {
        const foundTenant = (propertyUsers || []).find((tenant) => tenant.email === email);

        if (foundTenant) {
          setTenant(foundTenant);
        } else {
          history.push(ROUTES.TENANTS);
        }
      } else {
        history.push(ROUTES.TENANTS);
      }
    } catch (e) {
      history.push(ROUTES.TENANTS);
    }
  }, [emailHash, propertyUsers]);

  const pingMeters = () => {
    const meters = [...tenant.meters];
    const meterCodes = (meters || []).map(({ meter_code }) => meter_code);

    if (meterCodes && meterCodes.length > 0) {
      Promise.all(
        meterCodes.map((meter_code) =>
          client.query({
            query: $PING_METER_LIVENESS,
            variables: { meter_code },
          })
        )
      )
        .then((responses) => {
          responses.forEach(({ data: { pingMeterLiveness: { is_available } = { is_available: false } } = {} }: any = {}, index) => {
            meters[index] = { ...meters[index], is_available };
          });
        })
        .finally(() => {
          setTenant((t) => ({ ...t, meters }));
        });
    }
  };

  useEffect(() => {
    if (tenant && tenant.meters && tenant.meters.length > 0) {
      pingMeters();

      const pingMetersInterval = setInterval(() => {
        pingMeters();
      }, 30000);

      return () => clearInterval(pingMetersInterval);
    }
  }, [tenant.meters.length]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setActiveMeter((meter) => ({ ...meter, [name]: value }));
  };

  return (
    <>
      <DashboardContainer activeItem="tenants">
        <StyledContentWrapper>
          <div>
            <a onClick={() => history.goBack()}>Go Back</a>
          </div>
          <div>
            <div style={{ fontWeight: 600, marginTop: 20, marginBottom: 20 }}>Tenant Profile</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={tenant && tenant.picture}
                style={{
                  height: 80,
                  width: 80,
                  minWidth: 80,
                  borderRadius: '50%',
                  margin: 20,
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: '3px 0', fontSize: 16, fontWeight: 500 }}>
                  {tenant.firstname} {tenant.lastname}
                </div>
                <div style={{ padding: '3px 0' }}>{tenant.email}</div>
                <div style={{ padding: '3px 0' }}>{tenant.phone_number || '-'}</div>
              </div>
            </div>

            <StyledInfoContainer>
              <StyledInfoTitle>Current Information</StyledInfoTitle>
              <>
                {(tenant.meters || []).map((meter, index) => (
                  <div key={index}>
                    <StyledInfo>
                      <span>Meter:</span> <span>{meter.name}</span>
                      <StyledButton
                        color="primary"
                        onClick={() => {
                          setActiveMeter({ ...meter, customer: { ...tenant } });
                          setOpenModal({ payment: true });
                        }}
                      >
                        New Payment
                      </StyledButton>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Status:</span>{' '}
                      <span style={{ color: '#ababab' }}>
                        {meter.is_available === true ? (
                          <span style={{ color: '#0ca04b' }}>Alive</span>
                        ) : meter.is_available === false ? (
                          <span style={{ color: '#e0483d' }}>Dead</span>
                        ) : (
                          'pinging...'
                        )}
                      </span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Debt:</span> <span>₦{meter.debt || 0}</span>{' '}
                      <StyledButton
                        color="red"
                        onClick={() => {
                          setActiveMeter({ ...meter, debt: 0 });
                          setOpenModal({ debt: true });
                        }}
                      >
                        Add Debt
                      </StyledButton>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Units:</span>{' '}
                      <span>
                        {meter.units || 0} {meter.units > 1 ? 'units' : 'unit'}
                      </span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Tariff:</span> <span>₦{meter.meter_tariff || 0} per kWh</span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Billing Type:</span> <span>{meter.meter_billing_type}</span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Date Added:</span> <span>{meter.created_at}</span>
                    </StyledInfo>
                    <StyledInfo>
                      <StyledInfoTitle>Other Information</StyledInfoTitle>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Meter SN:</span> <span>{meter.info.sn}</span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Meter Enabled:</span> <span>{meter.is_enabled ? 'Yes' : 'No'}</span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Meter Type:</span> <span>{meter.meter_type}</span>
                    </StyledInfo>
                    <StyledInfo>
                      <span>Meter Comms:</span> <span>{meter.comms}</span>
                    </StyledInfo>
                  </div>
                ))}
              </>
            </StyledInfoContainer>
          </div>
        </StyledContentWrapper>
      </DashboardContainer>
      <Modal size="sm" show={openModal.debt} onHide={handleModalClose} aria-labelledby="add-debt-to-meter" centered>
        <Modal.Body>
          <div style={{ position: 'absolute', right: 5, top: 5, width: 18 }}>
            <span>
              <img style={{ cursor: 'pointer' }} src={closeIcon} onClick={handleModalClose} />
            </span>
          </div>
          <form
            style={{
              display: 'flex',
              fontSize: 14,
              marginTop: 20,
            }}
            onSubmit={(e) => {
              e.preventDefault();

              const { meter_code, meter_billing_type, meter_tariff, debt } = activeMeter;
              addDebt({
                variables: {
                  meter_code,
                  billing_type: meter_billing_type,
                  tariff: parseFloat(`${meter_tariff}`),
                  debt: parseFloat(`${debt}`),
                },
              })
                .then(({ errors = null }: any) => {
                  setToast({
                    show: true,
                    success: !errors,
                    error: !!errors,
                    message: errors ? 'Failed to make payment!' : 'Payment made!',
                  });
                  setOpenModal({ debt: false, payment: false });
                })
                .catch((e) => {
                  console.log('E', e);
                });
            }}
          >
            <Input
              id="debt"
              name="debt"
              placeholder="Enter Debt (₦)"
              type="number"
              required
              style={{ height: 42 }}
              min={0}
              onChange={handleInputChange}
              value={activeMeter.debt}
            />
            <FilledButton
              type="submit"
              disabled={loading}
              className={loading ? 'loading' : ''}
              style={{ width: 150, fontSize: 11, marginLeft: 10 }}
            >
              {!loading && 'Add Debt'}
            </FilledButton>
          </form>
        </Modal.Body>
      </Modal>

      <Modal size="sm" show={openModal.payment} onHide={handleModalClose} aria-labelledby="register-meter-payment" centered>
        <Modal.Body>
          <div style={{ position: 'absolute', right: 5, top: 5, width: 18 }}>
            <span>
              <img style={{ cursor: 'pointer' }} src={closeIcon} onClick={handleModalClose} />
            </span>
          </div>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 14,
              marginTop: 20,
              padding: 10,
            }}
            onSubmit={(e) => {
              e.preventDefault();

              const {
                meter_code,
                name: meter_name,
                sn: meter_sn,
                property_id,
                amount_paid,
                customer,
                payment_status_code,
                message,
                payment_type,
                tx_ref,
              } = activeMeter;

              const payment_status = PAYMENT_STATUS_MAP[payment_status_code] || 'unknown';

              const raw_response = {
                meter_sn,
                meter_name,
                customer_name: `${customer.firstname} ${customer.lastname}`.trim(),
                customer_email: customer.email,
                customer_phone: customer.phone_number,
                payment_status,
                amount_paid,
                message,
                meta: {
                  txref: tx_ref,
                  paymenttype: payment_type,
                },
              };

              makePayment({
                variables: {
                  meter_code: meter_code,
                  property_id: parseInt(property_id),
                  amount_paid: parseFloat(amount_paid),
                  payment_status: `${payment_status_code}`,
                  raw_response: raw_response,
                },
              })
                .then(({ data, errors = null }: any) => {
                  if (errors && errors.length > 0) {
                    setToast({
                      show: true,
                      success: false,
                      error: !!errors,
                      message: errors && errors.length > 0 ? errors[0] : 'Failed to make payment!',
                    });
                  } else {
                    if (data.payment) {
                      setToast({
                        show: true,
                        success: true,
                        error: false,
                        message: typeof data.payment === 'string' ? data.payment : 'Payment recorded!',
                      });
                    }
                  }
                  setOpenModal({ debt: false, payment: false });
                })
                .catch((e) => {
                  console.log('E', e);
                });
            }}
          >
            <Input
              id="amount_paid"
              name="amount_paid"
              placeholder="Enter Amount (₦)"
              type="number"
              required
              style={{ height: 42 }}
              min={0}
              onChange={handleInputChange}
            />
            <Input
              id="tx_ref"
              name="tx_ref"
              placeholder="Transaction Ref"
              type="text"
              required
              style={{ height: 42 }}
              onChange={handleInputChange}
            />
            <Select
              id="payment_type"
              name="payment_type"
              placeholder="Payment Channel"
              options={[
                { value: 'card', option: 'Card' },
                { value: 'link', option: 'Link' },
                { value: 'bank', option: 'Bank' },
              ]}
              required
              style={{ height: 42 }}
              onChange={handleInputChange}
            />
            <Select
              id="payment_status_code"
              name="payment_status_code"
              placeholder="Payment Status"
              options={[
                { value: '1', option: 'Success' },
                { value: '0', option: 'Failed' },
                { value: '-1', option: 'Unknown' },
              ]}
              required
              style={{ height: 42 }}
              onChange={handleInputChange}
            />
            <Input
              id="message"
              name="message"
              placeholder="Message (e.g Charge success)"
              type="text"
              required
              style={{ height: 42 }}
              onChange={handleInputChange}
            />
            <FilledButton
              type="submit"
              disabled={makePaymentLoading}
              className={makePaymentLoading ? 'loading' : ''}
              style={{ width: 150, fontSize: 11, margin: 'auto', marginTop: 10 }}
            >
              {!makePaymentLoading && 'Make Payment'}
            </FilledButton>
          </form>
        </Modal.Body>
      </Modal>
      <Toast
        show={toast.show}
        message={toast.message}
        title={toast.title}
        success={toast.success}
        error={toast.error}
        onClose={() => setToast({ show: false })}
      />
    </>
  );
};

export default TenantProfile;
