import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Table from '../../components/Table';
import { ROUTES } from '../../utilities/constants';
import { FilledButton } from '../../components/Button';
import DashboardContainer from '../../components/DashboardContainer';
import { useLazyQuery, useMutation } from '@apollo/client';
import { $GET_PROPERTY_USERS, $ADD_TENANTS_BY_CSV } from '../../queries';
import { getProperty } from '../../store/selectors';
import { setPropertyUsers } from '../../store/actions';
import { PropertyInterface } from '../../interfaces/property';

const tenantColumns = [
  { Header: 'S/N', accessor: 'sn' },
  {
    Header: '',
    accessor: 'picture',
    // eslint-disable-next-line react/display-name
    Cell: ({ value }) => {
      return (
        <img
          src={value}
          style={{
            height: '40px',
            width: 40,
            minWidth: 40,
            borderRadius: '50%',
          }}
        />
      );
    },
  },
  { Header: 'Name', accessor: 'fullname' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Phone Number', accessor: 'phone_number' },
];

const StyledContentWrapper = styled.div`
  padding: 20px;
`;

const Tenants = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [CSVFile, setCSVFile] = useState('');
  const property: PropertyInterface = useSelector(getProperty);
  const [fetchPropertyUsers, { data }] = useLazyQuery($GET_PROPERTY_USERS);

  const [addTenantsByCSV] = useMutation($ADD_TENANTS_BY_CSV, {
    context: {
      useMultipart: true,
    },
  });

  useEffect(() => {
    if (!data || property.property_id != data.property.property_id) {
      fetchPropertyUsers({ variables: { property_id: property.property_id } });
    }

    dispatch(setPropertyUsers((data && data.property && data.property.users) || []));
  }, [property.property_id, data]);

  const forceOpenModal = () => {
    setModalOpen(false);
    setModalOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setCSVFile(file);
  };

  const uploadCSVFile = () => {
    if (CSVFile) {
      const formData = new FormData();
      formData.append('file', CSVFile);
      formData.append('role_slug', 'property_tenant');
      formData.append('property_id', property.property_id);
      console.log('CSV: ', CSVFile);
      addTenantsByCSV({
        variables: { property_id: property.property_id, role_slug: 'property_tenant', file: formData },
      });
    }
  };

  const viewTenant = (data) => {
    if (data) {
      history.push(ROUTES.TENANT_VIEW + btoa(data.email));
    }
  };

  return (
    <>
      <DashboardContainer activeItem="tenants">
        <StyledContentWrapper>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FilledButton style={{ width: 160 }} type="button" onClick={forceOpenModal}>
              Add Tenant
            </FilledButton>
          </div> */}
          {data && data.property && data.property.users && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {data.property.users.length > 0 ? (
                <Table
                  data={(data.property.users || []).map(({ firstname, lastname, picture, phone_number, email }, index) => ({
                    sn: index + 1,
                    picture,
                    email,
                    fullname: `${firstname || ''} ${lastname || ''}`.trim(),
                    phone_number: phone_number || '-',
                  }))}
                  columns={tenantColumns}
                  onRowClick={viewTenant}
                />
              ) : (
                <span>No tenants in this property</span>
              )}
            </div>
          )}
        </StyledContentWrapper>
      </DashboardContainer>
      {modalOpen && (
        <Modal isOpen={modalOpen}>
          <div>
            <div>Upload CSV file of tenants</div>
            <Input type="file" placeholder="Upload CSV file" onChange={handleFileChange} />
          </div>
          <div>
            Download{' '}
            <a href="/samplefile.csv" download="samplefile.csv" style={{ textDecoration: 'underline' }}>
              {' '}
              sample csv file{' '}
            </a>
          </div>
          <div>
            <FilledButton type="button" onClick={uploadCSVFile}>
              Upload File
            </FilledButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Tenants;
