import Typography from 'typography';
import funstonTheme from 'typography-theme-funston';
import { injectFonts } from '../utilities/helpers';

funstonTheme.overrideThemeStyles = () => ({
  a: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  'a:hover': {
    color: '#0ca04b',
    textDecoration: 'none',
  },
  'h2, h3, h4': {
    lineHeight: 1.4,
  },
});

const typography = new Typography({
  ...funstonTheme,
  bodyFontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen'],
  headerFontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen'],
  googleFonts: undefined,
});

// Hot reload typography in development.
typography.injectStyles();
injectFonts(typography);

export default typography;
export const { rhythm, scale, options } = typography;
