import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

const StyledModal = styled.div`
  .close-modal {
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.secondary.base};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    height: 20px;
    justify-content: center;
    margin: 0;
    padding: 0 0 1px 0;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all 300ms;
    width: 20px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary.light};
    }

    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.colors.grey.light};
    }
  }
`;

const Modal = ({
  isOpen,
  title,
  subtitle,
  onClose = () => null,
  onAfterOpen = () => null,
  style = {},
  children,
}: {
  isOpen?: boolean;
  title?: string;
  subtitle?: string;
  onClose?: () => any;
  onAfterOpen?: () => any;
  style?: any;
  children?: any;
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={open}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      aria={{
        labelledby: title || '',
        describedby: subtitle || '',
      }}
      style={{
        content: {
          top: '40px',
          left: '50%',
          maxHeight: 'calc(100% - 80px)',
          width: 600,
          minWidth: '50%',
          maxWidth: '80%',
          right: 'auto',
          bottom: 'auto',
          boxShadow: '0 0 10px 10px rgb(30, 30, 30)',
          transform: 'translateX(-50%)',
          overflowY: 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 100,
        },
        ...style,
      }}
      contentLabel="Example Modal"
    >
      <StyledModal>
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <span className="close-modal" onClick={() => setOpen(false)}>
          &#0215;
        </span>
        {children}
      </StyledModal>
    </ReactModal>
  );
};

export default Modal;
