import React from 'react';
import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './styleguide/theme';
import { ROUTES } from './utilities/constants';
import { apolloClient } from './config/apollo';
import { PublicRoute, PrivateRoute, RestrictedRoute } from './config/router';
import Login from './pages/Login';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import Meters from './pages/Meters';
import Management from './pages/Management';
import Tenants from './pages/tenants/Tenants';
import TenantProfile from './pages/tenants/TenantProfile';
import './index.css';

ReactModal.setAppElement('#root');

const App = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <Switch>
            <PublicRoute exact path={ROUTES.HOME} component={Landing} />
            <PrivateRoute exact path={ROUTES.LOGIN} component={Login} backwardPass={true} />
            <RestrictedRoute exact path={ROUTES.METERS} allowedPermissions={[]} component={Meters} />
            <RestrictedRoute exact path={ROUTES.TENANTS} allowedPermissions={[]} component={Tenants} />
            <RestrictedRoute exact path={ROUTES.TENANT_VIEW_PARAM} allowedPermissions={[]} component={TenantProfile} />
            <RestrictedRoute exact path={ROUTES.MANAGEMENT} allowedPermissions={[]} component={Management} />
            <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
          </Switch>
          <ToastContainer />
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
