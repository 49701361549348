import React from 'react';
import { useTable, useGroupBy, useFilters, useSortBy, useExpanded, usePagination } from 'react-table';
import styled from 'styled-components';
import BTable from 'react-bootstrap/Table';
import { Typography } from '../styleguide/StyledTypography';
const { Paragraph, SmallLead } = Typography;

// https://blog.logrocket.com/building-styling-tables-react-table-v7/

// export interface TableProps<D, C> {
//   data: D[];
//   columns: C[];
// }

// const Table = <D extends, C>(): React.FC<TableProps<D, C>> => ({ data, columns }) => {
const Styles = styled.div<{ onClick?: any }>`
  padding: 40px;

  table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid ${({ theme }) => theme.colors.primary.base};
    // border-radius: 10px;
    width: auto;

    thead {
      background-color: ${({ theme }) => theme.colors.primary.base};
      padding: 10px;

      th {
        border: 0;
        color: white;
      }
    }

    tbody tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      cursor: pointer;
    }

    th,
    td {
      padding: 0.5rem;
      border-bottom: 1px solid #ddd;

      :last-child {
        border-right: 0;
      }
    }

    td {
      p {
        margin: 5px;
      }
    }
  }
`;

const StyledRow = styled.tr<{ textColor?: string }>`
  ${({ textColor }) =>
    textColor
      ? `
    p {
      color: ${textColor}
    }
  `
      : ''}
`;

const Table = ({ data, columns, onRowClick }: { data: any; columns: any; onRowClick?: any }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <Styles>
      <BTable striped hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledRow onClick={onRowClick && (() => onRowClick(row.original))} {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index: number) => {
                  return (
                    <td {...cell.getCellProps()} key={index}>
                      <Paragraph>{cell.render('Cell')}</Paragraph>
                    </td>
                  );
                })}
              </StyledRow>
            );
          })}
        </tbody>
      </BTable>
    </Styles>
  );
};

export default Table;
