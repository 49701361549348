// https://tobbelindstrom.com/blog/how-to-create-a-breakpoint-mixin-with-styled-components/
import { css } from 'styled-components';

export const breakpoints: {
  xs: string;
  sm: string;
  md: string;
  lg: string;
} = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
};

export const respondTo: {
  xs?: typeof css;
  sm?: any;
  md?: any;
  lg?: typeof css;
} = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...(args as [{}]))};
    }
  `;
  return accumulator;
}, {});
