export const getUser = (store) => store.user;

export const getUserProperties = (store) => (getUser(store) ? getUser(store).properties : []);

export const getUserProperty = (store, property_id) =>
  getUserProperties(store) ? getUserProperties(store).find((p) => p.property_id === property_id) : null;

export const getUserToken = (store) => (getUser(store) ? getUser(store).token : null);

export const getMeters = (store) => (getUser(store) ? getUser(store).meters : []);
