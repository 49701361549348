import React from 'react';
import styled from 'styled-components';
import { respondTo } from 'styleguide/breakpoints';

export const StyledSelect = styled.select<{ [key: string]: any }>`
  background-color: white;
  padding: ${({ theme }) => theme.space.S}px ${({ theme }) => theme.space.L}px;
  margin: ${({ theme }) => theme.space.M}px 0;
  height: 46px;
  border: 1px solid ${({ theme }) => theme.colors.primary.base};
  color: ${({ theme }) => theme.colors.secondary.base};
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  }

  ${respondTo.md`
    width: 100%;
  `}
`;

const Select = ({ options, placeholder, defaultValue = '', ...rest }) => (
  <StyledSelect {...rest} defaultValue={defaultValue}>
    <>
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {options.map(({ value, option }, index) => (
        <option key={`${value}-${index}`} value={value || option}>
          {option}
        </option>
      ))}
    </>
  </StyledSelect>
);

export default Select;
