import { createStore } from 'redux';
import rootReducer from './reducers'; //Import the reducer
import { configureForage } from '../services/forage';
import { persistStore, persistReducer } from 'redux-persist';
import enhancer from './enhancer';

const loalForage = configureForage();

const persistConfig = {
  key: 'root',
  storage: loalForage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store: any = createStore(persistedReducer, enhancer);

  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
