import { UserAction, UserActionTypes } from '../actions';

const INITIAL_STATE = {};

const authReducer = (state = INITIAL_STATE, action: UserAction) => {
  const { type, payload } = action;

  switch (type) {
    case UserActionTypes.SET_USER:
      return { ...state, ...payload };

    case UserActionTypes.LOG_OUT:
      return {};

    default:
      return state;
  }
};

export default authReducer;
