import { Subscription } from 'rxjs';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { infoToast } from './toast';

export const capitalize = (str) => {
  if (str && typeof str === 'string') {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        const match: { index: number } | any = word.match(/[A-Za-z]/);

        if (match && match.index >= 0) {
          return word.slice(0, match.index) + word.charAt(match.index).toUpperCase() + word.slice(match.index + 1, word.length);
        }

        return `${word.charAt(0)}`.toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  return str;
};

export const compare = (a: number | string, b: number | string, isAsc: boolean) => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const unsubscribe = (...subscribers: Subscription[]) => {
  if (subscribers) {
    subscribers.forEach((subscriber) => {
      if (subscriber && subscriber.unsubscribe) {
        subscriber.unsubscribe();
      }
    });
  }
};

export const isObjectEqual = (objA, objB) => {
  return isEqual(objA, objB);
};

/**
 * formatDate
 *
 * transform/format a date using DatePipe
 * @param date
 * @param {string} format
 * @param {string} local
 * @returns {string}
 */
export const formatDate = (date, format = 'D MMM YYYY', local = 'en-US') => {
  if (date) {
    return moment(date).format(format);
  }

  return date;
};

/**
 * formatCurrency
 *
 * transform/format a date using DatePipe
 * @param value
 * @param {string} currency
 * @param {string} local
 * @returns {string}
 */
export const formatCurrency = (value, currency = '₦ ', local = 'en-US') => {
  if (!isNaN(value)) {
    // return _formatCurrency(value, local, currency, 'NGN', '1.0-0');
  }

  return value;
};

export const isEmpty = (obj) => {
  return (
    obj === undefined ||
    obj === null ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0) ||
    (typeof obj === 'string' && obj.trim().length === 0)
  );
};

export const isEmptyObject = (obj) => {
  return !(typeof obj === 'object' && Object.keys(obj).length > 0);
};

/**
 * encodeB64
 *
 * encode string to base 64 using **`btoa`** library
 * @param {string} str
 * @returns {string}
 */
export const encodeB64 = (str: string) => {
  return btoa(str);
};

/**
 * generatePromise
 *
 * generate promise from any asynchronous data
 * @param data
 * @returns {Promise<any>}
 */
export const generatePromise = (data): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (typeof data !== 'boolean' && data) {
      resolve(data);
    } else {
      reject(data);
    }
  });
};

export const responseHandler = (res: any, successCallback?) => {
  const { data, total, code, error, message } = res;
  let { status } = res;
  status = status || (code === 200 && !error);

  if (status && successCallback) {
    successCallback({ data, status, message });
  }

  return { status, message, data, total };
};

export const errorHandler = (err: any, show?: boolean) => {
  let payload;
  if (err.error) {
    if (err.error.data) {
      payload = err.error.data;
    } else {
      payload = err.error;
    }
  } else {
    payload = err;
  }

  const { status, message } = payload;

  if (show) {
    infoToast(message);
  }

  return { data: null, status, message: status === 0 ? 'Please check your internet connection' : message };
};

export const dateFromNow = (date, short = false) => {
  return moment(date).fromNow(short);
};

export const buildChartData = (
  datasets: any,
  labels: any[],
  option: {
    type: string;
    horizontal?: boolean;
    xtype?: string;
    xtitle?: string;
    ytype?: string;
    ytitle?: string;
    title?: string;
    subtitle?: string;
    sort?: 'asc' | 'desc';
  } = {
    type: 'line',
  }
) => {
  let settings: any;

  settings = {
    type: option.type,
    title: {
      text: option.title,
    },
  };

  if (option.type === 'bar') {
    settings = {
      ...settings,
      plotOptions: { bar: { horizontal: !!option.horizontal } },
      xaxis: { categories: labels, title: { text: option.xtitle } },
      yaxis: { title: { text: option.ytitle } },
    };
  } else if (option.type === 'line') {
    settings = {
      ...settings,
      xaxis: { categories: labels, type: option.xtype, title: { text: option.xtitle } },
      yaxis: { title: { text: option.ytitle } },
    };
  } else if (option.type === 'pie' || option.type === 'donut') {
    settings = { ...settings, labels };
  }

  return { datasets, settings };
};

export const keepDefinedValues = (data: { [key: string]: any } | any) => {
  if (!isEmpty(data)) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (typeof value !== 'undefined' && value !== null && value !== undefined) {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  return data;
};

export const mergeDeepArray = (array) => {
  const isObject = (obj) => obj && typeof obj === 'object';
  const isArray = (obj) => Array.isArray(obj);

  if (isArray(array)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return array.map((element) => (isArray(element) ? mergeDeepArray(element) : isObject(element) ? mergeDeep({}, element) : element));
  }

  return array;
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const mergeDeep = (...objects) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = mergeDeepArray(pVal.concat(...oVal));
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

export const range = (start, end?, interval = 1) => {
  let size = start;
  let offset = 0;
  if (end && interval > 0) {
    size = Math.floor((end - start + 1) / interval);
    offset = start;
  }

  return [...(Array(size).keys() as any)].map((v) => offset + v * interval);
};

export const isElementInViewport = (el: any) => {
  const rect = el.getBoundingClientRect();

  return (
    (rect.top <= 0 && rect.bottom >= 0) ||
    (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
    (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
  );
};

export const throttle = (fn, wait) => {
  let time = Date.now();

  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const injectLink = (link, href = '') => {
  const typoElt = document.getElementById('typography.js');
  if (typoElt) {
    typoElt.insertAdjacentHTML('afterend', link);
  }
};

export const getFontsStr = (typography) => {
  let fontsStr = '';
  if (typography.options.googleFonts) {
    const fonts = typography.options.googleFonts.map((font) => {
      let str = '';
      str += font.name.split(' ').join('+');
      str += ':';
      str += font.styles.join(',');

      return str;
    });

    fontsStr = fonts.join('|');
  }

  return fontsStr;
};

export const getFontsLink = (fontsStr) => {
  const href = `//fonts.googleapis.com/css?family=${fontsStr}`;
  const link = `<link href="${href}" rel="stylesheet" type="text/css" />`;
  return { link, href };
};

export const injectFonts = (typography) => {
  const fontsStr = getFontsStr(typography);
  if (fontsStr) {
    const { link, href } = getFontsLink(fontsStr);
    injectLink(link, href);
  }
};
