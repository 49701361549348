export const colors = {
  primary: '#239852',
  primaryLight: '#3cbb6f',
  primaryDark: '#167b3f',
  secondary: '#22516a',
  secondaryLight: '#347698',
  secondaryDark: '#174a65',
  link: '#1890ff',
  success: '#52c41a',
  warning: '#faad14',
  error: '#e84118',
  heading: '#423EA2',
  text: '#22516a',
  disabled: 'rgba(0, 0, 0, 0.12)',
  disabledText: 'rgba(0, 0, 0, 0.26)',
  border: '#423EA2',
};
