import React from 'react';
import NavBar from '../components/NavBar';
import styled from 'styled-components';
import { respondTo } from '../styleguide/breakpoints';

const StyledLandingWrapper = styled.div`
  height: 100vh;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);

  ${respondTo.sm`
    flex-direction: row;
    height: calc(100% - 64px);

    > div {
      width: 50%;
    }
  `}
`;

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${respondTo.sm`
    flex: 3;
  `}
`;

const LandingContainer: React.FC = ({ children }) => {
  return (
    <StyledLandingWrapper>
      <NavBar items={[]}></NavBar>
      <StyledContentContainer>
        <StyledMainContentContainer>{children}</StyledMainContentContainer>
      </StyledContentContainer>
    </StyledLandingWrapper>
  );
};

export default LandingContainer;
