import React from 'react';
import Toast from 'react-bootstrap/Toast';

const StyledToast = ({ show = false, title = '', message = '', success = false, error = false, onClose = () => {} }: any) => (
  <Toast
    onClose={onClose}
    show={show}
    delay={3000}
    autohide
    style={{
      position: 'fixed',
      top: 20,
      right: 20,
      background: '#ffffff',
      border: '1px solid',
      borderColor: success ? '#0ca04b' : error ? '#a00c4b' : '#aaaaaa',
      minWidth: 200,
    }}
  >
    {(title || success || error) && (
      <Toast.Header>
        <strong className="mr-auto">{title || success ? 'Successful' : error ? 'Something went wrong' : ''}</strong>
      </Toast.Header>
    )}
    {message && <Toast.Body>{message}</Toast.Body>}
  </Toast>
);

export default StyledToast;
