import { AnyAction } from 'redux';
import { UserInterface } from '../../interfaces/user';

export enum UserActionTypes {
  SET_USER = 'SET_USER',
  LOG_OUT = 'LOG_OUT',
}

export interface AddUserAction extends AnyAction {
  type: UserActionTypes.SET_USER;
  payload?: UserInterface;
}

export interface LogOutUserAction extends AnyAction {
  type: UserActionTypes.LOG_OUT;
}

export const setUserData = (userData: UserInterface): AddUserAction => {
  return {
    payload: userData,
    type: UserActionTypes.SET_USER,
  };
};

export const logOutUser = (): LogOutUserAction => {
  return {
    type: UserActionTypes.LOG_OUT,
  };
};

export type UserAction = AddUserAction | LogOutUserAction;
