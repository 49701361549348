import { PropertyAction, PropertyActionTypes } from '../actions';

const INITIAL_STATE = {};

const propertyReducer = (state = INITIAL_STATE, action: PropertyAction) => {
  const { type, payload }: any = action;

  switch (type) {
    case PropertyActionTypes.SET_PROPERTY:
      return { ...state, ...payload };

    case PropertyActionTypes.SET_PROPERTY_USERS:
      return { ...state, users: [...payload] };

    default:
      return state;
  }
};

export default propertyReducer;
