import { colors } from './colors';
import { StyledSystemProps } from './DynamicStyledSystemComponent';

const fontFamilies: { heading: string; body: string } = {
  heading: 'Segoe UI Bold',
  body: 'Segoe UI Regular',
};

interface TypographyStyles {
  H1: StyledSystemProps;
  H2: StyledSystemProps;
  H3: StyledSystemProps;
  H4: StyledSystemProps;
  H5: StyledSystemProps;
  LargeLead: StyledSystemProps;
  SmallLead: StyledSystemProps;
  Paragraph: StyledSystemProps;
  SmallParagraph: StyledSystemProps;
  Link: StyledSystemProps;
}
export const typographyStyles: TypographyStyles = {
  H1: {
    fontSize: 50,
    fontWeight: 700,
    color: colors.text,
    fontFamily: fontFamilies.heading,
    as: 'h1',
  },
  H2: {
    fontSize: 37,
    fontWeight: 700,
    color: colors.primary,
    fontFamily: fontFamilies.heading,
    as: 'h2',
  },
  H3: {
    fontSize: 27,
    fontWeight: 700,
    color: colors.text,
    fontFamily: fontFamilies.heading,
    as: 'h3',
  },
  H4: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.primary,
    fontFamily: fontFamilies.heading,
    as: 'h4',
  },
  H5: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.text,
    fontFamily: fontFamilies.heading,
    as: 'h5',
  },
  LargeLead: {
    fontWeight: 300,
    fontSize: 18,
    color: colors.text,
    fontFamily: fontFamilies.body,
    as: 'p',
  },
  SmallLead: {
    fontWeight: 300,
    fontSize: 17,
    color: colors.text,
    fontFamily: fontFamilies.body,
    as: 'p',
  },
  Paragraph: {
    fontSize: 14,
    fontWeight: 300,
    color: colors.text,
    fontFamily: fontFamilies.body,
    as: 'p',
  },
  SmallParagraph: {
    fontSize: 13,
    fontWeight: 300,
    color: colors.text,
    fontFamily: fontFamilies.body,
    as: 'p',
  },
  Link: {
    fontWeight: 700,
    color: colors.primary,
    fontSize: 14,
    fontFamily: fontFamilies.body,
  },
};
